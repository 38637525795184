import React, { useEffect, useState } from 'react';
import './App.css';
import Preloader from './New folder/preloader';
import Page from './page';

function App() {

  const[loading,setLoading] = useState(true);

  useEffect(() => {
    // Show the preloader for 2 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds

    return () => clearTimeout(timer); // Cleanup on unmount
  }, []);

  
  return (
    <div>
      {loading ? (
        <Preloader />
      ) : (
        <Page />
      )}
    </div>
  );
}

export default App;
